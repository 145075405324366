import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
export type token = string | null;
import { getStorageData } from "../../../framework/src/Utilities";


interface PointOfSale {
  id: string;
  type: string;
  attributes: {
    program_name: string;
    company: string;
    authorized_employees: boolean;
    last_closing_date: string | null;
    last_closing_cash_balance: string;
    pos_interface: {
      id: number;
      product_configurator: boolean;
      customer_notes: boolean;
      floors_and_tables: boolean;
      internal_notes: boolean;
      category_pictures: boolean;
      restrict_product_categories: boolean;
      start_category: boolean;
      large_scrollbars: boolean;
      open_product_info: boolean;
      limited_partners_loading: boolean;
      point_of_sale_id: number;
      created_at: string;
      updated_at: string;
    };
    connected_device: {
      id: number;
      iot_box: boolean;
      direct_devices: boolean;
      customer_display: boolean;
      order_printer: boolean;
      barcode_nomenclature: string;
      point_of_sale_id: number;
      created_at: string;
      updated_at: string;
    };
    tax: {
      id: number;
      fiscal_position_per_order: boolean;
      fiscal_position: boolean;
      point_of_sale_id: number;
      created_at: string;
      updated_at: string;
    };
    pos_pricing: {
      id: number;
      advanced_pricelists: boolean;
      tax_excluded_price: boolean;
      tax_included_price: boolean;
      global_discounts: boolean;
      gift_card: boolean;
      manual_discounts: boolean;
      coupons_and_promotions: boolean;
      price_control: boolean;
      default_pricelist: string;
      discount_product: string;
      discount_percentage: string;
      loyalty_program: string;
      point_of_sale_id: number;
      created_at: string;
      updated_at: string;
      multiple_price_per_product: null | string;
      advanced_price_rules: null | string;
    };
    pos_payment: {
      id: number;
      set_maximum_diffrence: boolean;
      tips: boolean;
      available_payment_methods: string[];
      point_of_sale_id: number;
      created_at: string;
      updated_at: string;
      cash_rounding: null | string;
      payment_methods: null | string;
      payment_date: null | string;
      session_id: null | string;
    };
    bill_and_receipt: {
      id: number;
      header_and_footer: boolean;
      automatic_receipt_printing: boolean;
      bill_printing: boolean;
      bill_spitting: boolean;
      invoice_journal: string;
      point_of_sale_id: number;
      created_at: string;
      updated_at: string;
    };
    pos_inventory: {
      id: number;
      limited_products_loading: boolean;
      ship_later: boolean;
      operation_type: string;
      point_of_sale_id: number;
      created_at: string;
      updated_at: string;
    };
    pos_accounting: {
      id: number;
      sales_journal: string;
      point_of_sale_id: number;
      created_at: string;
      updated_at: string;
      default_sales_tax: null | string;
      default_intermediary_account: null | string;
    };
    pos_sale: {
      id: number;
      sales_team: string;
      down_payment_product: string;
      point_of_sale_id: number;
      created_at: string;
      updated_at: string;
    };
  };
}

interface PointOfSalesResponse {
  key: string;
  point_of_sales: {
    data: PointOfSale[];
  };
}

type posInfoType = {
  id: string;
  type: string;
  checked: boolean;
  popperAnchor: HTMLElement | null;
  program_name: string;
  company: string;
  last_closing_date: string;
  last_closing_cash_balance: string;
};

interface PayloadType {
  key: string;
  data: posInfoType[]; 
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isViewTypeChanged: boolean;
  anchorElement: HTMLElement | null;
  selectedFilter: string;
  anchorEl: HTMLElement | null;
  isAllChecked: boolean;
  isChecked: boolean;
  token: token;
  responseData:any;
  currentPage: number;
  rowsPerPage: number;
  totalCounts: number;
  row: {
    from: number;
    end: number;
  };
  totalPage: number;
  dataLength: number;
  query: string;
  anchorElFilter: HTMLElement | null;
  anchorElGroupBy: HTMLElement | null;
  selectedItems: {[name: string]: boolean;};
  params: { [name: string]: boolean | string };
  isGroupby: boolean;
  isLoading: boolean;
  queryItems: { id: string, tag: string, key: string, type: string }[];
  isExpanded:boolean[];
  isLoadMore: boolean;
  initialLoadComplete: boolean;
  remainingItems: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfposscreen4Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPOSListing: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isViewTypeChanged: true,
      anchorElement: null,
      selectedFilter: "",
      anchorEl: null,
      isAllChecked: false,
      isChecked: false,
      token: "",
      responseData:[],
      currentPage: 1,
      rowsPerPage: 9,
      totalCounts: 0,
      row: {
        from: 1,
        end: 9,
      },
      totalPage: 0,
      dataLength: 0,
      query: "",
      anchorElFilter: null,
      anchorElGroupBy: null,
      selectedItems: {},
      params: {},
      isGroupby: false,
      isLoading: false,
      queryItems: [],
      isExpanded:[],
      isLoadMore: false,
      initialLoadComplete: false,
      remainingItems: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
      runEngine.debugLog(
        "API Message Recived", message
      );
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    const token = await getStorageData("TOKEN")
    this.setState({ token: token });
    if (!token) {this.props.navigation.navigate("EmailAccountLoginStyles")}
    else {this.posListingData();
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    const token = this.state.token;
    if (prevState.query !== this.state.query ||
      (prevState.currentPage !== this.state.currentPage ||
        prevState.rowsPerPage !== this.state.rowsPerPage)
    ) {
      let searchString = ""
      if (this.state.query.length >= 3) {
        searchString = `&search=${this.state.query}`
      }
      const { currentPage, rowsPerPage } = this.state;
      const params = `page=${currentPage}&per_page=${rowsPerPage}${searchString}`
      this.getPOSListing = this.handleApiCall(token,
        `/bx_block_point_of_sale/point_of_sales?${params}`, "GET"
      );
    }
    else if (
      prevState.currentPage !== this.state.currentPage ||
      prevState.rowsPerPage !== this.state.rowsPerPage ||
      prevState.queryItems !== this.state.queryItems) {
      this.posListingData()
    }
  }
  groupByOptions = [
    { name: "Company Name", key: "company" },
  ];

  filterOptions = [
    { name: "Archieved", key: "archeive" },
    { name: "Active", key: "active" },
  ];

  SettingOptions = [
    { name: "Export" },
    { name: "Archive" },
    { name: "Unarchive" },
    { name: "Delete" },
  ];



  receiveApi = (
    apiRequestCallId: string,
    responseJson: {data: PointOfSale[] | PointOfSalesResponse[]
      meta: { total_pages: number; 
        total_counts: number 
      };
    }
  ) => {
    if (apiRequestCallId === this.getPOSListing) {
      this.handleGetItemResponse(responseJson.data,
        responseJson.meta.total_pages,responseJson.meta.total_counts
      );
    }
  };


  handleGetItemResponse = (
    data: PointOfSale[] | PointOfSalesResponse[],
    totalPage: number,
    totalCounts: number
  ) => {
    const tableInfo = this.state.isGroupby ?
      this.getCollapseTableKey(data as PointOfSalesResponse[]) : this.getTableKey(data as PointOfSale[]);
    this.setState({
      responseData: tableInfo,
      totalPage, totalCounts,
      isLoading: false,
      isLoadMore: false,
    })
  };


  getTableKey = (data: PointOfSale[]) => {
    console.log(data,"datadata")
    const requiredData: posInfoType[] = [];
    data.forEach((item: PointOfSale) => {
      const attributes = item.attributes;
      const type = item.type;
      const id = item.id;
      const posInfo: posInfoType = {
        id,
        type,
        checked: false,
        popperAnchor:null,
        program_name: attributes.program_name || "",
        company: attributes.company || "",
        last_closing_date: attributes.last_closing_date || "",
        last_closing_cash_balance: attributes.last_closing_cash_balance || "",
      };
      requiredData.push(posInfo);
    })
    return requiredData;
  }

  getCollapseTableKey = (data: PointOfSalesResponse[]) => {
    const requiredData = data?.map((item: PointOfSalesResponse) => {
      let payload : PayloadType = {
        "key": item.key,
        data: []
      }
      payload.data = this.getTableKey(item.point_of_sales.data)
      return payload
    })
    return requiredData;
  }


  handleSettingsClickAway = () => {
    this.setState({ 
      anchorEl: null 
    });
  };

  handleFilterClickAway = () => {
    this.setState({ 
      anchorElFilter: null 
    });
  };
  
  handleGroupClickAway = () => {
    this.setState({ 
      anchorElGroupBy: null 
    });
  };


  handleClickGroupBy = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({anchorElGroupBy: this.state.anchorElGroupBy ? null : event.currentTarget,});
  };

  handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({anchorElFilter: this.state.anchorElFilter ? null : event.currentTarget,});
  };

  handleClickSetting = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({anchorEl: this.state.anchorEl ? null : event.currentTarget,});
  };

  handleGroupBySelect = (item: { name: string; key: string }) => {
    if (!this.state.isViewTypeChanged) {
      this.setState({
        isViewTypeChanged: true,
      })
    }
    this.setState((prevState) => { const updatedQueryItems = [...prevState.queryItems];
      const existingIndex = updatedQueryItems.findIndex((queryItem) => queryItem.type === "groupby");
      if (existingIndex !== -1) {updatedQueryItems[existingIndex] = {id: item.key,tag: item.name,key: item.key,type: "groupby", };} 
      else {updatedQueryItems.push({id: item.key,tag: item.name,key: item.key,type: "groupby",});}
      return { queryItems: updatedQueryItems };
    });
    this.setState({
      isAllChecked: false,
      anchorElGroupBy: null,
      isGroupby: true,
      selectedItems: {}
    })
  };

  handleChangePage = (pageNo: number) => {
    this.setState({currentPage: pageNo + 1,});
    if (this.state.currentPage > pageNo) {this.setState((prev) => ({row: {from: prev.row.from - this.state.rowsPerPage,end: prev.row.end - this.state.rowsPerPage,},}));} 
    else {this.setState((prev) => ({row: {from: prev.row.from + this.state.rowsPerPage,end: prev.row.end + this.state.rowsPerPage,},}));}
    this.setState({isAllChecked: false,})
  };

  handleLoadMore = () => {
    const { totalCounts, rowsPerPage, remainingItems, initialLoadComplete } = this.state;
    const leftOver = totalCounts - rowsPerPage;
    const newRowsPerPage = rowsPerPage + 18;
    this.setState({ remainingItems: leftOver, isLoadMore: true, rowsPerPage: newRowsPerPage })

    if (!initialLoadComplete || (leftOver > 0 && remainingItems > 0)) {
      this.setState({
        remainingItems: leftOver,
        isLoadMore: true,
        initialLoadComplete: true,
        rowsPerPage: newRowsPerPage,
      });
    }
  }

  handleFilterAllChecked = (updatedSelectedItem: { [name: string]: boolean }) => {
    const filterData = 
    this.state.responseData as posInfoType[]
    const checkBoxData = filterData.map((item: posInfoType) => {
      item.checked = !this.state.isAllChecked;
      if (item.checked) {
        updatedSelectedItem[item.id] = item.checked;
      } else {if(updatedSelectedItem.hasOwnProperty(item.id)) {delete updatedSelectedItem[item.id];}}
      return item;
    });
    this.setState((prev) => {
      return {
        selectedItems: updatedSelectedItem,
        isAllChecked: !prev.isAllChecked,
        responseData: checkBoxData,
      };
    });
    this.setState({isLoading: false,})
  }

  handleGroupbyAllChecked = (updatedSelectedItem: { [name: string]: boolean }) => {
    const groupbyData = 
    this.state.responseData as PayloadType[]
    const checkBoxData = groupbyData.map((element: PayloadType) => {
      let updatedElement = element
      updatedElement.data = element.data?.map((item: posInfoType) => {
        item.checked = !this.state.isAllChecked;
        if (item.checked) {
          updatedSelectedItem[item.id] = item.checked;
        } 
        else {if (updatedSelectedItem.hasOwnProperty(item.id)) { delete updatedSelectedItem[item.id];}}
        return item;
      })
      return updatedElement
    }
    );
    this.setState((prev) => {
      return {
        selectedItems: updatedSelectedItem,isLoading: false,isAllChecked: !prev.isAllChecked,responseData: checkBoxData,
      };
    });
  }

  handleAllChecked = () => {
    let updatedSelectedItem = Object.assign({}, this.state.selectedItems);
    if (!this.state.isGroupby) {this.handleFilterAllChecked(updatedSelectedItem)} 
    else {this.handleGroupbyAllChecked(updatedSelectedItem)}
  };

  handleFilterSelect = (item: { name: string; key: string }) => {
    this.setState(
      (prevState) => ({queryItems: [...prevState.queryItems, 
        { id: item.key, tag: item.name, key: item.key, type: "filter" }]
    }));
    this.setState({ 
      anchorElFilter: null,
      isAllChecked: false,
      selectedItems: {}
    });
  };

  handleChangeRowsPerPage= (event: any)=> {
    this.setState({
        currentPage: 1,
        rowsPerPage: +(event.target.value) ,
    });
  };

  toggleColumn = (anchorElement: HTMLElement | null) => {
    this.setState({ anchorElement });
  };

  toggleIsExpanded = (rowKey: number) => {
    const expandableRows=this.state.isExpanded
    expandableRows[rowKey] = !expandableRows[rowKey]
    this.setState({isExpanded:expandableRows})
  };

  onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({query: value});
    return {};
  }

  handleRemoveTag = (item: { id: string; tag: string; key: string; type: string; }) => {
    const updatedQueryItems = this.state.queryItems.filter((queryItem) => queryItem.key !== item.key);
    if (item.type === "groupby") {
      this.setState({
        isGroupby: false ,
        queryItems: updatedQueryItems , 
        query: "",
        currentPage: 1,
        isAllChecked:false,
        selectedItems:{}
      });
    }
    else {
      this.setState({
        isAllChecked: false,
        selectedItems: {},
        queryItems: updatedQueryItems,
        query: "",
        currentPage: 1,
      });
    }
  }

  posListingData = async () => {
    const token = this.state.token;
    const { currentPage, rowsPerPage } = this.state;
    let params = {} as { [name: string]: boolean | string }
    this.state.queryItems.forEach((item: {
      id: string;
      tag: string;
      key: string;
      type: string;
    }) => {
      if (item.hasOwnProperty("key")) { params[item.key] = true }
    })

    const pstr = Object.keys(params).map(function (key) {return key + "=" + params[key];}).join("&");
    this.getPOSListing = this.handleApiCall(token,
      this.state.isGroupby ? `/bx_block_point_of_sale/point_of_sales?${pstr}` : 
      `/bx_block_point_of_sale/point_of_sales?page=${currentPage}&per_page=${rowsPerPage}&${pstr}`,"GET"
    );
  };


  handleCheckBoxSelectGroupby = (item: posInfoType, index: number, updatedSelectedItem: { [x: string]: boolean }, checkBoxData: PayloadType[]) => {
    if (item.checked) {updatedSelectedItem[item.id] = true;} 
    else if (updatedSelectedItem.hasOwnProperty(item.id)) {delete updatedSelectedItem[item.id];}

    checkBoxData.forEach((element:any,index:number)=>{element.data.forEach((rowEle:posInfoType,rowind:number)=>{if(rowEle.id==item.id){element=item}})})
    return checkBoxData.every((element: any) => {return element.data.every((item: any) => item.checked) === true})
  }

  handleCheckBoxSelectFilter = (item: posInfoType, index: number, updatedSelectedItem: { [x: string]: boolean }, checkBoxData: posInfoType[]) => {
    if (item.checked) {
      updatedSelectedItem[item.id] = true;
    } 
    else if (updatedSelectedItem.hasOwnProperty(item.id)) { 
      delete updatedSelectedItem[item.id];
    }
    checkBoxData[index] = item;
    return checkBoxData.every((item: posInfoType) => item.checked);
  }
  
  handleCheckBoxSelect = (item: posInfoType, index: number) => {
    const updatedSelectedItem = { ...this.state.selectedItems };
    const checkBoxData = [...this.state.responseData];
    let isAllChecked = false
    item.checked = !item.checked;
    if (this.state.isGroupby) {
      isAllChecked = this.handleCheckBoxSelectGroupby(item, index, updatedSelectedItem, checkBoxData);
    } else {
      isAllChecked = this.handleCheckBoxSelectFilter(item, index, updatedSelectedItem, checkBoxData);
    }

    this.setState({
      responseData: checkBoxData,
      selectedItems: updatedSelectedItem,
      isAllChecked: isAllChecked,
    });
    this.setState({
      isLoading: false, isLoadMore: false,
    })
  };


  handleApiCall = (
    token: token, 
    endPoint: string, 
    method: string, 
    body?: object) => {
    
    const header = {
      "Content-Type": "application/json",
      token: token, 
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),method);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));


    runEngine.sendMessage(
      requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  // Customizable Area End
}
